/* App Styles */
.App {
  text-align: center;
  font-family: 'Inter', 'Montserrat', sans-serif;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

/* Navbar Styles */
.navbar-button {
  margin-right: 20px;
}

.nav-link {
  color: #777 !important;
  font-size: small !important;
}

/* Hover state */
.nav-link:hover {
  color: #000 !important; /* Custom hover color */
}

.navbar-brand {
  color: #555 !important;
  font-weight: 600 !important;
  font-family: 'Josefin Sans', sans-serif !important;
  font-size: x-large !important;
}

/* General styles for both arrows */
.default-nav {
  margin: 0.5em !important; /* Add margin */
}

/* In your CSS file */
.dark-gray-nav-link {
  color: #333 !important; /* Using !important to override default styles */
}

.container_about {
  margin-top: 50px;
}

/* Gallery styles */
.gallery-container {
  width: 100vw;
  height: 100vh;
  margin: auto;
  position: relative;
  overflow: hidden;
}

.slide-container {
  width: 100vw;
  height: 100vh;
}

.each-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: relative;
}

.each-slide div {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
}

/* Slide Caption Styles */
.slide-caption {
  position: absolute !important; 
  bottom: 0 !important; 
  width: 100%;
  height: 100px !important;
  color: white;
  text-align: center;
  padding: 10px;
}

/* Before After Slider Styles */
.before-after-slider {
  width: 100vw;
  height: 100vh !important;
  background-size: cover;
  background-position: center;
  position: relative;
}

.before-after-slider__first-photo-container,
.before-after-slider__second-photo-container {
  width: 100vw;
  height: 100vh !important;
  position: absolute;
  top: 0;
  left: 0;
}

button {
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}